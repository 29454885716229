<template>
  <Article pageType="add"></Article>
</template>

<script>
import Article from '../components/Article.vue'
export default {
  components: {
    Article
  }
}
</script>

<style>
</style>
